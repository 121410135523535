// extracted by mini-css-extract-plugin
export var awardCard = "cN_vm";
export var awardCard__bdg_large = "cN_vr";
export var awardCard__bdg_small = "cN_vs";
export var awardCard__container = "cN_vy";
export var awardCard__description = "cN_vx";
export var awardCard__info = "cN_vt";
export var awardCard__large = "cN_vp";
export var awardCard__link = "cN_vw";
export var awardCard__post = "cN_vn";
export var awardCard__small = "cN_vq";
export var awardCard__title = "cN_vv";